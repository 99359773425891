import React from "react";
import { Cookie } from "../../actions/util";
import { Modal } from "antd";

const init2024State = {
  langModalDisplay: false,
  telegramModalDisplay: false,
  telegramModalCloserHeight: 0,
};
const LANGMODAL_HIDE_ACTION = 'langModalHide';
const LANGMODAL_SHOW_ACTION = 'langModalShow';
const TELEGRAM_SHOW_ACTION = 'telegramShow';
const TELEGRAM_HIDE_ACTION = 'telegramHide';
const TELEGRAM_CLOSER_SHOW_ACTION = 'telegramCloserShow';
function reducer(state, action) {
  let newState;
  switch (action.type) {
    case LANGMODAL_SHOW_ACTION:
      newState = { langModalDisplay: true };
      break;
    case LANGMODAL_HIDE_ACTION:
      newState = { langModalDisplay: false };
      break;
    case TELEGRAM_SHOW_ACTION:
      newState = { telegramModalDisplay: true, telegramModalCloserHeight: 0 };
      break;
    case TELEGRAM_HIDE_ACTION:
      newState = { telegramModalDisplay: false };
      break;
    case TELEGRAM_CLOSER_SHOW_ACTION:
      newState = { telegramModalCloserHeight: 25 };
      break;
    default:
      throw new Error('Invalid action: ' + JSON.stringify(action));
  }
  return newState;
}

class Footer extends React.Component{
	constructor(props){
		super(props);
		this.state = {...init2024State, ...{
			telegramModal: false,
			lineModal: false,
			seoOpen: false,
			end_page_list:[
				{link : 'help/gamble-responsibly', text:'Điều kiện và Điều khoản'},
				{link : 'help/disclaimer', text:'Tuyên bố trách nhiệm'},
				{link : 'help/privacy-policy', text:'Chính sách Bảo mật'},
				{link : 'help/betting-rules', text:'Quy định và Luật lệ'},
				{link : 'https://www.h32lucky.com/vi-vn',text : 'Đại Lý'},
			]
		}}
	}



	/**
	 *
	 * @param {String} link
	 * @param {Number} key
	 */
	 openLink(link , key){
		// 如果 URL 中携带 affcode 则获取出来
		let aff = Cookie.GetCookieKeyValue('CO_affiliate') || '';
		let { mainSiteUrl } = this.props;
		try {
      let URL;
			switch (key) {
				case 4:
					URL = link;
					break;
				default:
					URL = mainSiteUrl + link + `${aff ? `?aff=${aff}` : ''}`;
					break;
			}
      window.open( URL, '_blank', 'noopener,noreferrer');
		} catch (error) {
			console.log('====================================');
			console.log(error);
			console.log('====================================');
		}
	}

	render() {
    return this.render2023(this.props.is2023Ver2);
    // return this.props.isRender2024 ? this.render2024() : this.render2023();
  }

  render2023(is2023Ver2){
		return (
			<>
				<div className={`footer-content-web` + (is2023Ver2 ? ' v2of2023' : '')}>
          {is2023Ver2 ? this.render2024footerTopWrapper(true) :
					<div className={"partner"}>
						{/* 伙伴 */}
						<div className="item partner-item">
							<div className="title">Đối Tác</div>
							<div className="image-box">
								<div>
									<div className="default partner-item-0"></div>
									<div className="footer-image-box-text">
									 	<a href="https://www.tottenhamhotspur.com/home/" className="border">Đối tác cá cược châu Á chính thức</a>
									 	<a href="#">Tottenham Hotspur FC</a>
									</div>
								</div>
								<div>
									<div className="default parrtner-item-1"></div>
									<div className="footer-image-box-text">
									 	<a href="https://www.nufc.co.uk/" className="border">Nhà tài trợ áo chính thức</a>
									 	<a href="#">Newcastle United FC</a>
									</div>
								</div>
							</div>
						</div>

						{/* 供应商 */}
						<div className="item suppliers">
							<div className="title">Nhà Cung Cấp</div>
							<div className="suppliers-box">
								<div className="suppliers-default suppliers-item-0"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-1"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-2"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-3"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-4"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-5"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-6"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-7"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-8"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-9"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-10"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-11"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-12"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-13"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-14"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-15"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-16"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-17"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-18"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-19"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-20"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-21"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-22"></div>
								<div className="suppliers-default suppliers-public-style suppliers-item-23"></div>
							</div>
						</div>
						{/* 品牌形象大使 */}
						<div className="item brand">
							<div className="title">Đại Sứ Thương Hiệu</div>
							<div style={{display:'flex'}}>
								<div className="brand-default brand-item-0"></div>
							</div>

						</div>
					</div>
          }

					{/* 版权、 pay 、安全、责任*/}
					<div className="copyright-pay-safe">
						<div className="copyright">
							<h6>Giấy phép: </h6>
							<div className="icon"></div>
						</div>
						<div className="bank-pay">
							<h6>Thanh toán: </h6>
							<div className='icon icon-1'></div>
							<div className='icon icon-2'></div>
							<div className='icon icon-3'></div>
							<div className='icon icon-4'></div>
							<div className='icon icon-5'></div>
							<div className='icon icon-6'></div>
						</div>
						<div className="safe">
							<h6>Bảo vệ:</h6>
							<div className="icon"></div>
						</div>
						<div className="responsibillity">
							<h6>Trách nhiệm:</h6>
							<div>
								<div className="icon icon-1"></div>
								<div className="icon icon-2"></div>
							</div>

						</div>
					</div>


					{/* end-page */}
					<div className="end-page">
						{/* กฎและข้อกำหนด ความรับผิดชอบ นโยบายความเป็นส่วนตัว กฎทั่วไป พันธมิตร */}
						<ul className="end-page-list">
							{this.state.end_page_list.map((val , key)=>{
								return (
									<li onClick={()=>{this.openLink(val.link , key)}} className="item" key={key}>{val.text}</li>
								)
							})}
						</ul>

						{/* 帮助 和 contact 联系方式*/}
						<div className="help-contact">
							{/* 帮助 */}
							<div className="help">
								<img width="25" src={'/static/images/hayhar/icon-help.png'} className="help-icon" />
								<span onClick={()=>{this.openLink('help/ve-fun88' , 6)}}>Giúp Đỡ</span>
							</div>
							{/* 联系 */}
							<div className='contact-box'>
                <a target='_blank'  href='https://t.me/Fun88CS' className={'item'}/>
								<img width="25" src={'/static/images/hayhar/flag-thb.png'} className="item country" />
							</div>
						</div>

						<div className="end-page-footer-text">
							<p>©Mọi Bản Quyền Thuộc FUN88.</p>
						</div>
						<div className="seoHeader" onClick={()=>{this.setState({seoOpen: !this.state.seoOpen})}}>
							Cá cược thể thao uy tín số 1 Việt Nam tại Fun88 <img width="15" style={{transform: this.state.seoOpen ? 'rotate(180deg)' : ''}} src={'/static/images/hayhar/arrowDown.png'} />
						</div>
							{this.state.seoOpen &&
								<div className="seoBody">
									<p><b>Fun88</b> là một trong những cái tên nổi bật trong danh sách những nhà cái cungcấp trò chơi cá cược trực tuyến uy tín nhất hiện nay. Được biết đến như một thếgiới thu nhỏ về các dịch vụ cá cược thể thao, <b>Fun88</b> cung cấp tất cả các trò chơihấp dẫn nhất hiện nay để đáp ứng nhu cầu khách hàng.</p>
									<p><b>I. Tổng quan về nhà cái Fun88</b></p>
									<p><b>Fun88</b> tự hào là một môi trường cá cược công bằng và lành mạnh nhất tại khuvực châu Á. Trước khi lấn sân sang thị trường Việt Nam, Fun88 đã mất vài nămđể tìm hiểu và nghiên cứu sở thích của người Việt Nam. Mặc dù gặp khá nhiềukhó khăn về luật nhà nước Việt Nam, nhưng <a href="https://soikeotot.com/nha-cai-fun88/" style={{color: 'white'}}>Fun 88</a> đã chính thức tìm đượchướng đi và địa điểm đặt chân chi nhánh chính thức tại đất nước Philippinesmột cách hợp lệ. Nhờ đó mà ngày nay, người chơi tại Việt Nam có thể đăng kýtham gia cá cược tại Fun88 mà không lo bị nhòm ngó hay sợ vi phạm pháp luật.</p>
									<p>Sau quãng thời gian 10 năm hoạt động trên thị trường, <b>Fun88</b> đã khẳng định được thương hiệu của mình và đứng vững trong top những nhà cái uy tín nhất trên thị trường cá cược hiện nay. <b>Fun88</b> không chỉ phổ biến ở thị trường châu Á mà với việc tài trợ và hợp tác với các CLB nổi tiếng ở giải NHA như Tottenham Hotspur, Newcastle United FC… đã giúp Fun 88 được biết đến nhiều hơn ở cả thị trường châu Âu, châu Mỹ.</p>
									<p>Fun88 được cấp giấy phép bởi tổ chức Gaming Associates cho phép cung cấp đa dạng các dịch vụ về cá cược thể thao, casino trực tuyến, các game online, xổ số trực tuyến cùng hiệu quả cho người chơi ở khắp nơi trên thế giới với lợi thế đa ngôn ngữ.</p>
									<p>Vào năm 2009 và 2010, <b>Fun88</b> đạt được một số giải thưởng lớn do tạp chí eGaming Review bình chọn. Đó cũng là bằng chứng cho thấy sự thành công và mức độ uy tín của thương hiệu Fun88 trong lòng khách hàng.</p>
									<p><b>II. Fun88 có những ưu điểm nổi bật gì?</b></p>
									<p>Hiện nay trên thị trường đang có hàng nghìn thương hiệu nhà cái cá cược khác nhau cùng hoạt động. Mỗi nhà cái đều có những lợi thế riêng, tuy nhiên Fun88 vẫn luôn khẳng định được thương hiệu của riêng mình trong suốt những năm qua. Theo đánh giá của những thành viên lâu năm, Fun88 là địa chỉ cá cược uy tín hàng đầu hiện nay nhờ vào những ưu điểm nổi bật sau:</p>
									<p>&nbsp; &#8226; Là một nhà cái lâu đời, thương hiệu Fun88 được xây dựng hơn 10 năm với lượng khách hàng đông đảo, trung thành.</p>
									<p>&nbsp; &#8226; Tất cả các sản phẩm tại Fun88 đều được cung cấp bởi các công ty sản xuất game hàng đầu trên thế giới và đảm bảo tính minh bạch, công bằng trong từng kết quả và có <a style={{color: 'white'}} href="https://www.fun88vi.com/vn/the-thao/">tỷ lệ cá cược</a> cao nhất mang đến cho khách hàng.</p>
									<p>&nbsp; &#8226; Thiết kế giao diện thông minh, hiện đại, dễ sử dụng với đa dạng ngôn ngữ khác nhau phù hợp với nhiều đối tượng trên toàn thế giới.</p>
									<p>&nbsp; &#8226; Công nghệ bảo mật hiện đại đạt chuẩn quốc tế SSL – 128 bit, tất cả các thông tin của khách hàng cung cấp đều được mã hóa, bảo mật tuyệt đối không bị tiết lộ ra ngoài ngay cả khi có dấu hiệu bị xâm nhập.</p>
									<p>&nbsp; &#8226; <b>Fun88</b> cam kết mang tới lợi nhuận cao nhất cho thành viên, cao hơn các trang web khác tới 20%.</p>
									<p>&nbsp; &#8226; Fun88 cung cấp đầy đủ các hình thức cá cược phổ biến nhất hiện nay như cá cược thể thao, <a style={{color: 'white'}} href="https://xoso88.tv/fun88-xo-so">Fun88 xổ số</a>, cá cược trực tiếp bóng đá, cá cược esports, Fun88 casino…</p>
									<p>&nbsp; &#8226; Cung cấp nhiều chương trình khuyến mãi hấp dẫn cho tất cả các thành viên tham gia, giá trị lên tới cả chục triệu đồng.</p>
									<p>&nbsp; &#8226; Ngoài ra, đội ngũ chuyên viên chuyên nghiệp của Fun88 còn chia sẻ các Tipcuoc88, mẹo cá cược để khách hàng đạt hiệu quả cao nhất khi tham gia.	</p>
									<p><b>III. Hướng dẫn tham gia vào Fun88</b></p>
									<p><b>1. Đăng ký tài khoản Fun88</b></p>
									<p>Để có tài khoản Fun88 các bạn có thể thực hiện các bước sau:</p>
									<p>&nbsp; &#8226; <b>Bước 1:</b> Truy cập vào trang chủ của Fun88, chọn mục THAM GIA NGAY</p>
									<p>&nbsp; &#8226; <b>Bước 2:</b> Điền đầy đủ các thông tin cá nhân như Tên đăng nhập, mật khẩu, số điện thoại ..</p>
									<p>&nbsp; &#8226; <b>Bước 3:</b> Kiểm tra lại thông tin và nhấn ĐĂNG KÝ NGAY để hoàn tất quá trình đăng ký.</p>
									<p><b>2. Cách gửi tiền vào tài khoản Fun88</b></p>
									<p>&nbsp; &#8226; <b>Bước 1:</b> Truy cập vào website Fun88 rồi chọn ô GỬI TIỀN</p>
									<p>&nbsp; &#8226; <b>Bước 2:</b> Lựa chọn một trong số những hình thức gửi tiền được gợi ý như Zalo Pay, Momo pay, QR Pay, fastPay, ngân hàng địa phương…</p>
									<p>&nbsp; &#8226; <b>Bước 3:</b> Điền các thông tin theo bảng yêu cầu tùy theo từng hình thức.</p>
									<p>&nbsp; &#8226; <b>Bước 4:</b> Sau khi đã điền xong nhập Hoàn tất để thanh toán.</p>
									<p><b>3. Cách rút tiền tài khoản Fun88</b></p>
									<p>&nbsp; &#8226; <b>Bước 1:</b> Đăng nhập vào tài khoản Fun88, chọn ô RÚT TIỀN </p>
									<p>&nbsp; &#8226; <b>Bước 2:</b> Điền các thông tin đầy đủ theo yêu cầu như Tên chủ tài khoản, số tài khoản, ngân hàng địa phương,...</p>
									<p>&nbsp; &#8226; <b>Bước 3:</b> Kiểm tra thông tin và bấm ô CHUYỂN TIỀN để hoàn tất.</p>
									<p><b>IV. Kết luận</b></p>
									<p>Như vậy có thể thấy, không chỉ có sự đa dạng về sản phẩm mà dịch vụ chăm sóc khách hàng chuyên nghiệp của <b>Fun88</b> đã giúp nhà cái này ngày càng khẳng định được vị trí của mình trong ngành giải trí trực tuyến. Tipcuoc88.com chia sẻ với tất cả những giá trị mà <b>Fun88</b> mang đến cho khách hàng, chắc chắn nhà cái này sẽ còn vươn xa hơn nữa trong tương lai và trở thành điểm đến số 1 trên thị trường châu Á nói chung và ở Việt Nam nói riêng.</p>
								</div>
							}


					</div>

				</div>
				{this.state.telegramModal &&
					<Modal
						zIndex={9999}
						visible={true}
					>
						<img width="250" src={'/static/images/hayhar/telegram-qrcode.jpeg'} />
						<div className="closeModal"onClick={()=> this.setState({telegramModal: false})}>&nbsp; &nbsp; ĐÓNG</div>
					</Modal>
				}
				{this.state.lineModal &&
					<Modal
						zIndex={9999}
						visible={true}
					>
						<img width="250" src={'/static/images/hayhar/line-qrcode.jpg'} />
						<div className="closeModal"onClick={()=> this.setState({lineModal: false})}>&nbsp; &nbsp; ĐÓNG</div>
					</Modal>
				}


			</>
		)
	}

  render2024Vendors(isDisplayIn2023UI) {
    return (<div
      className={isDisplayIn2023UI ? 'item vendors-item' : 'row expanded collapse small-8 show-for-large'}
      style={isDisplayIn2023UI ? {} : { paddingTop: "1em" }}
    >
      <div className={isDisplayIn2023UI ? 'footer-first-wrapper' : ''}>
        <span className={isDisplayIn2023UI ? 'footer-top-title' : 'footer-bottom-title'}>Nhà Cung Cấp</span>
      </div>
      <ul className="footer-icon">
        <li>
          <span
            className="footer-gaming-platform asiagaming lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform bti lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform microgaming lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform onework lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform playtech lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform im lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform cmd lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform tg lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform gpk lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform tgp lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform evo lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform sgw lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform tcg lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform tfg lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform wmc lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform wec lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform sxy lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform jif lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform kpk lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform pgs lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform png lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform vtg lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform spr lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform evp lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        <li>
          <span
            className="footer-gaming-platform hbn lazy"
            style={{
              backgroundImage:
                'url("/ft/footer_vendor.webp")'
            }}
          />
        </li>
        {[[76,42,'BNG'], [76,42,'NAGA'], [109,42,'BigTimeGaming'], [95,42,'RED_TIGER']].map(v=>
        <li>
          <span
            className="footer-gaming-platform lazy"
            style={{
              display:'inline-block',
              width:v[0],
              height:v[1],
              backgroundSize: 'cover',
              background:
                'url("/ft/providers/'+v[2]+'.svg") 50% no-repeat'
            }}
          />
        </li>
        )}
      </ul>
    </div>)
  }
  render2024LangModal() {
    return (
      <>
      <div className="reveal-overlay" style={{ display: this.state.langModalDisplay ? 'block' : 'none' }}>
  <section
    id="selectRegionLanguage"
    className="Modal-Language-Wrapper"
    data-reveal="nyogqc-reveal"
    style={{ top: 192 }}
    role="dialog"
    aria-hidden="false"
    data-yeti-box="selectRegionLanguage"
    data-resize="selectRegionLanguage"
    data-events="resize"
    tabIndex={-1}
  >
    <div>
      <span
        onClick={()=>{
          this.setState(
            reducer(this.state, {type: LANGMODAL_HIDE_ACTION})
          )
        }}
        className="icon icon-close-circle-button Modal-Language-Close-Button"
        data-close=""
      />
      <span className="Model-Language-Header">Chọn khu vực và ngôn ngữ</span>
    </div>
    <div className="Model-Language-Content-Wrapper">
      <div className="row expanded collapse">
        <div className="column medium-6 small-12 end">
          <div style={{ padding: "5px 0" }}>
            <p className="language-region-languageName">Trung Quốc</p>
            <span
              className="flag-icons flag-icons-CN"
              style={{ verticalAlign: "middle" }}
            />
            <div className="language-wrapper">
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'CNY_footer_homepage']);"
                  className="language-region-languageName"
                  href="/cn/"
                >
                  中文
                </a>
              </span>
              <span>|</span>
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'CNY_ENG_footer_homepage']);"
                  className="language-region-languageName"
                  href="/cn/en/"
                >
                  English
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="column medium-6 small-12 end">
          <div style={{ padding: "5px 0" }}>
            <p className="language-region-languageName">Thái Lan</p>
            <span
              className="flag-icons flag-icons-TH"
              style={{ verticalAlign: "middle" }}
            />
            <div className="language-wrapper">
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'THB_footer_homepage']);"
                  className="language-region-languageName"
                  href="/th/"
                >
                  ภาษาไทย
                </a>
              </span>
              <span>|</span>
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'THB_ENG_footer_homepage']);"
                  className="language-region-languageName"
                  href="/th/en/"
                >
                  English
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="column medium-6 small-12 end">
          <div style={{ padding: "5px 0" }}>
            <p className="language-region-languageName">Việt Nam</p>
            <span
              className="flag-icons flag-icons-VN"
              style={{ verticalAlign: "middle" }}
            />
            <div className="language-wrapper">
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'VND_footer_homepage']);"
                  className="language-region-languageName"
                  href="/vn/"
                >
                  Tiếng Việt
                </a>
              </span>
              <span>|</span>
              <span>
                <a
                  onclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'VND_ENG_footer_homepage']);"
                  className="language-region-languageName"
                  href="/vn/en/"
                >
                  English
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
      </>
    )
  }

  render2024footerTopWrapper(isDisplayIn2023UI) {
    return <section className="footer-top-Wrapper">
    <div className={`row expanded collapse` + (isDisplayIn2023UI ? ' large-12' : ' large-11')}>
      <div className={`column ${isDisplayIn2023UI ? '' : 'large-6'} small-12 sponsor-col`}>
        <div className="footer-first-wrapper">
          <span className="footer-top-title">Đối Tác</span>
        </div>
        <div className="row expanded collapse sponsor-item">
          <div className={`column ${isDisplayIn2023UI ? '' : 'large-4'} small-6 ${isDisplayIn2023UI ? '' : 'large-offset-2'}`}>
            <div
              className="sponsor-newcastle centralize"
              title="Nhà Tài Trợ Áo Thi Đấu Chính Thức Cho Newcastle United - Giải Ngoại Hạng Anh"
            >
              <a
                href="https://www.nufc.co.uk/"
                target="_blank"
                style={{ display: "block" }}
                rel="noopener"
              >
                <img
                  className="lazy sponsor-logo"
                  data-original="/ft/logo-NUFC.webp"
                  alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                  title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                  src="/ft/logo-NUFC.webp"
                  style={{}}
                />
                <div className="sponsor-wording-top">
                  Nhà Tài trợ Áo Chính thức
                </div>
                <p className="sponsor-wording-btm">Newcastle United FC</p>
              </a>
            </div>
          </div>
          <div className={`column ${isDisplayIn2023UI ? '' : 'large-4'} small-6`} style={isDisplayIn2023UI ? {} : { float: "left" }}>
            <div
              className="sponsor-tottenham centralize"
              title="Đối Tác Cá Cược Chính Thức Của Tottenham Hotspur Tại Châu Á - Giải Ngoại Hạng Anh"
            >
              <a
                href="https://www.tottenhamhotspur.com/home/"
                target="_blank"
                style={{ display: "block" }}
                rel="noopener"
              >
                <img
                  className="lazy sponsor-logo"
                  data-original="/ft/logo-SPURS.png"
                  alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                  title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                  src="/ft/logo-SPURS.png"
                  style={{}}
                />
                <div className="sponsor-wording-top">
                  Đối tác Cá cược Chính thức
                </div>
                <p className="sponsor-wording-btm">Tottenham Hotspur FC</p>
              </a>
            </div>
          </div>
        </div>
        {/* row expanded collapse sponsor-item */}
      </div>
      {isDisplayIn2023UI ?
      <div className={`column small-12 vendors-col`}>
        {this.render2024Vendors(true)}
      </div>
      : null}
      <div className={`column ${isDisplayIn2023UI ? '' : 'large-6'} small-12 ambassador-col`}>
        <div className="footer-first-wrapper">
          <span className="footer-top-title">Đại Sứ Thương Hiệu</span>
        </div>
        <div className="row expanded collapse centralize ambassador-desktop">
          <img
            className="lazy"
            data-original="/ft/brand-ambassador.webp"
            style={{ backgroundImage: "none" }}
            alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
            title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
            src="/ft/brand-ambassador.svg"
          />
          <span></span>
        </div>
        <div
          id="amb-carousel"
          className="row expanded collapse centralize ambassador-mobile owl-carousel owl-loaded owl-drag owl-hidden"
          style={{display:'none'}}
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transition: "all 0s ease 0s",
                width: 3885,
                transform: "translate3d(-1554px, 0px, 0px)"
              }}
            >
              <div className="owl-item cloned" style={{ width: 777 }}>
                <div className="small-12">
                  <img
                    className="amb lazy"
                    data-original="/ft/brand-ambassador.png"
                    alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    src="/ft/brand-ambassador.png"
                    style={{}}
                  />
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 777 }}>
                <div className="small-12">
                  <img
                    className="amb lazy"
                    data-original="/ft/brand-ambassador.png"
                    alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    src="/ft/brand-ambassador.png"
                    style={{}}
                  />
                </div>
              </div>
              <div className="owl-item active center" style={{ width: 777, pointerEvents: 'none' }}>
                <div className="small-12">
                  <img
                    className="amb lazy"
                    data-original="/ft/brand-ambassador.png"
                    alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    src="/ft/brand-ambassador.png"
                    style={{}}
                  />
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 777 }}>
                <div className="small-12">
                  <img
                    className="amb lazy"
                    data-original="/ft/brand-ambassador.png"
                    alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    src="/ft/brand-ambassador.png"
                    style={{}}
                  />
                </div>
              </div>
              <div className="owl-item cloned" style={{ width: 777 }}>
                <div className="small-12">
                  <img
                    className="amb lazy"
                    data-original="/ft/brand-ambassador.png"
                    alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                    src="/ft/brand-ambassador.png"
                    style={{}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav disabled">
            <button type="button" role="presentation" className="owl-prev">
              <img
                id="btnPrevious"
                className="lazy"
                data-original="https://cache.fzkdsp.com//images/button-prev.png"
                alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
              />
            </button>
            <button type="button" role="presentation" className="owl-next">
              <img
                id="btnNext"
                className="lazy"
                data-original="https://cache.fzkdsp.com//images/button-next.png"
                alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
              />
            </button>
          </div>
          <div className="owl-dots disabled">
            <button role="button" className="owl-dot active">
              <span />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  }

  render2024() {

		return (
			<>
			<footer id="allFooter">
  {this.render2024footerTopWrapper(false)}
  <section className="footer-second-wrapper">
    <div className="row expanded collapse small-12">
      <div className="row expanded collapse large-8 small-10 linkcontainer">
        <div
          className="column small-12 hide-for-large"
          style={{ paddingBottom: "2rem" }}
        >
          <div
            className="row expanded collapse small-12"
            style={{ paddingBottom: "1rem" }}
          >
            <div>
              <span className="footer-bottom-title">Đối Tác</span>
            </div>
          </div>
          <div className="row expanded collapse small-12">
            <div className="column small-4 small-offset-2">
              <a
                href="https://www.nufc.co.uk/"
                target="_blank"
                style={{ display: "block" }}
                rel="noopener"
              >
                <div
                  className="Logo-NUFC lazy"
                  data-original="/ft/Logo-NUFC-mobile.webp"
                  style={{
                    backgroundImage:
                      'url("/ft/Logo-NUFC-mobile.webp")'
                  }}
                />
              </a>
            </div>
            <div className="column small-4" style={{ float: "left" }}>
              <a
                href="https://www.tottenhamhotspur.com/home/"
                target="_blank"
                style={{ display: "block" }}
                rel="noopener"
              >
                <div
                  className="Logo-SPURS lazy"
                  data-original="/ft/Logo-SPURS-mobile.webp"
                  style={{
                    backgroundImage:
                      'url("/ft/Logo-SPURS-mobile.webp")'
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="column large-5 show-for-large footerlink">
          <div className="row collapse small-12">
            <div className="column large-11 small-12 end">
              <div className="footer-column-container">
                <ul className="column-list column large-4 small-4">
                  <li className="footer-bottom-title">SẢN PHẨM</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/the-thao/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'SP_footer_homepage']);"
                    >
                      THỂ THAO
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/sportsbook/esportshome.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Esports_footer_homepage']);"
                    >
                      E-SPORTS
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/games/instantgames.htm"
                      xxclick="_paq.push(['trackEvent', 'Game Nav', 'Click', 'InstantGames_Footer']);"
                    >
                      Game Siêu Tốc
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/casino/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Live_footer_homepage']);"
                    >
                      CASINO
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/slots/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'SLOT_footer_homepage']);"
                    >
                      TRÒ CHƠI
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/p2p/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', '3Dcasino_footer_homepage']);"
                    >
                      3D CASINO
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/xo-so/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Lottery_footer_homepage']);"
                    >
                      XỔ SỐ
                    </a>
                  </li>
                </ul>
                <ul className="column-list column large-4 small-4">
                  <li className="footer-bottom-title">Thêm</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/khuyen-mai/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Promo_footer_homepage']);"
                    >
                      Khuyến Mãi
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      data-content-target="MoreReward"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Reward_footer_homepage']); Rewards.syncLogin(true);"
                      href="#"
                    >
                      Fun88 Giải Thưởng
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/moi-ban-be/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'RAF_footer_homepage']);"
                    >
                      Giới Thiệu Bạn Bè
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/download/home.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Download_footer_homepage']);"
                    >
                      Tải Về
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/ho-tro/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Help_footer_homepage']);"
                    >
                      Trợ Giúp
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/sponsorship/home.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Sponsorship_footer_homepage']);"
                    >
                      Clb Tài Trợ
                    </a>
                  </li>
                </ul>
                <ul className="column-list column large-4 small-4">
                  <li className="footer-bottom-title">Trung Tâm Trợ Giúp</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/ve-fun88/"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'About_footer_homepage']);"
                    >
                      Liên Hệ Fun88
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener"
                      href="#"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Affiliate_footer_homepage']); Page.openAffURL('/lmr/vi-vn')"
                    >
                      Đại Lý
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/policy-rulesandregulations.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'RulesRegulations_footer_homepage']);"
                    >
                      Quy định và Luật lệ
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/policy-disclaimer.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Disclaimer_footer_homepage']);"
                    >
                      Tuyên bố trách nhiệm
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/policy-privacy.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Policy_footer_homepage']);"
                    >
                      Chính sách <br />
                      Bảo mật
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/policy-termsandcondition.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Terms_footer_homepage']);"
                    >
                      Điều kiện và <br />
                      Điều khoản
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener"
                      href="/vn/help/policy-responsiblegaming.htm"
                      xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'ResponsibleGambling_footer_homepage']);"
                    >
                      Đặt Cược <br />
                      có Trách Nhiệm
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column large-6">
          <div className="row small-12" style={{ paddingBottom: 20 }}>
            <div className="column large-4 small-6 trim-padding-horizontal footer-social-media-div">
              <div>
                <span className="footer-bottom-title">Truyền thông xã hội</span>
              </div>
              <ul className="footer-social-ul">
                <li>
                  <a
                    href="https://www.facebook.com/FunSportVietNam"
                    target="_blank"
                    rel="noopener"
                    xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'FB_footer_homepage']);"
                  >
                    <div
                      className="footer-social-media vn facebook lazy"
                      data-original="/ft/footer_socialmedia.webp"
                      title="Like FUN88 tại Facebook"
                      style={{
                        backgroundImage:
                          'url("/ft/footer_socialmedia.webp")'
                      }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@funsportgame8887"
                    target="_blank"
                    rel="noopener"
                    xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Youtube_footer_homepage']);"
                  >
                    <div
                      className="footer-social-media vn youtube lazy"
                      data-original="/ft/footer_socialmedia.webp"
                      title="Theo dõi FUN88 tại Youtube"
                      style={{
                        backgroundImage:
                          'url("/ft/footer_socialmedia.webp")'
                      }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/funsportvn/"
                    target="_blank"
                    rel="noopener"
                    xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'IG_footer_homepage']);"
                  >
                    <div
                      className="footer-social-media vn insta lazy"
                      data-original="/ft/footer_socialmedia.webp"
                      title="Theo dõi Fun88 tại Instagram"
                      style={{
                        backgroundImage:
                          'url("/ft/footer_socialmedia.webp")'
                      }}
                    />
                  </a>
                </li>
                <li>
                  <div
                    className="footer-social-media vn telegram lazy"
                    onClick={()=>{
                      this.setState(
                        reducer(this.state, {type: TELEGRAM_SHOW_ACTION})
                      );
                    }}
                    data-original="/ft/footer_socialmedia.webp"
                    xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Telegram_footer_homepage']); BaseModal.TelegramInfo();"
                    title="Kết bạn với FUN88 tại Telegram"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_socialmedia.webp")'
                    }}
                  ></div>
                </li>
              </ul>
            </div>
            <div className="column large-8 small-6 responsibilities">
              <div>
                <span className="footer-bottom-title">Trách nhiệm</span>
              </div>
              <ul className="footer-icon">
                <li>
                  <a
                    href="https://www.gamcare.org.uk"
                    target="_blank"
                    rel="noopener"
                  >
                    <span
                      className="GameCareResponsibility FooterIcon lazy"
                      data-original="/ft/footer_payment.webp"
                      style={{
                        backgroundImage:
                          'url("/ft/footer_payment.webp")'
                      }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="/vn/help/policy-responsiblegaming.htm"
                    target="_blank"
                    rel="noopener"
                  >
                    <span
                      className="AboveAgeResponsibility FooterIcon lazy"
                      data-original="/ft/footer_payment.webp"
                      title="21+"
                      style={{
                        backgroundImage:
                          'url("/ft/footer_payment.webp")'
                      }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row small-12">
            <div className="column large-4 small-12 security">
              <div>
                <span className="footer-bottom-title">Bảo mật</span>
              </div>
              <ul className="footer-icon">
                <li>
                  <span
                    className="IovationSecurity FooterIcon lazy"
                    data-original="/ft/footer_payment.webp"
                    title="Iovation"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="column large-8 small-12 payment">
              <div>
                <span className="footer-bottom-title">Lựa chọn Thanh toán</span>
              </div>
              <ul className="footer-icon">
                <li>
                  <span
                    className="FooterIcon MomoPay lazy"
                    data-original="/ft/footer_payment.webp"
                    title="MomoPay"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
                <li>
                  <span
                    className="FooterIcon LocalBankTransferVn lazy"
                    data-original="/ft/footer_payment.webp"
                    title="Ngân Hàng Địa Phương"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
                <li>
                  <span
                    className="FooterIcon FastPayVN lazy"
                    data-original="/ft/footer_payment.webp"
                    title="FastPay"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
                <li>
                  <span
                    className="FooterIcon ZaloPay lazy"
                    data-original="/ft/footer_payment.webp"
                    title="ZaloPay"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
                <li>
                  <span
                    className="FooterIcon QRCode lazy"
                    data-original="/ft/footer_payment.webp"
                    title="QR Code"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
                <li>
                  <span
                    className="FooterIcon CashCardVn lazy"
                    data-original="/ft/footer_payment.webp"
                    title="Cash Card"
                    style={{
                      backgroundImage:
                        'url("/ft/footer_payment.webp")'
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="column large-1 small-6 show-for-large">
          <div
            onClick={()=>{
              this.setState(
                reducer(this.state, {type: LANGMODAL_SHOW_ACTION})
              )
            }}
            data-open="selectRegionLanguage"
            className="region-selector"
            aria-controls="selectRegionLanguage"
            aria-haspopup="true"
            tabIndex={0}
          >
            <span
              className="lazy flag-icons flag-icons-VN"
              data-original="/ft/flag-icons.webp"
              style={{
                backgroundImage:
                  'url("/ft/flag-icons.webp")'
              }}
            />
          </div>
        </div>
      </div>
      {this.render2024Vendors()}
    </div>
  </section>
  <section className="footer-third-wrapper">
    <div className="row expanded collapse large-8 small-12">
      <div className="column large-2 small-12">
        <div
          className="Montenegro-Seal lazy"
          data-original="/ft/Montenegro-Seal.webp"
          style={{
            backgroundImage:
              'url("/ft/Montenegro-Seal.webp")'
          }}
        ></div>
      </div>
      <div className="montenegro-desc white column large-9 small-11">
        <p>
          www.fun88.com is operated by E Gambling Montenegro d.o.o. (81000
          Podgorica, Moskovska br. 65., reg. no.5-0615951) in cooperation with
          OG GLOBAL ACCESS LIMITED (P.O. Box 3340, Road Town, Tortola, British
          Virgin Islands .{" "}
        </p>
        <p>
          E Gambling Montenegro d.o.o. (81000 Podgorica, Moskovska br. 65., reg.
          no.5-0615951) hereby certifies that under the concession (serial no.
          AA 001588, numeric no. 133-01/15 4A and 1ER) and approval (no.
          02/01-118/4), organize and operate games of chance in Montenegro on
          website www.fun88.com, in accordance with the Agreement, concluded
          between E Gambling Montenegro d.o.o. and OG GLOBAL ACCESS LIMITED
          (P.O. Box 3340, Road Town, Tortola, British Virgin Islands, reg. no.
          1923130) on 07 October 2021{" "}
        </p>
      </div>
    </div>
    <br />
    <div className="row expanded collapse small-11 end hide-for-large mobile-footer-link">
      <div className="row expanded collapse small-12 top-mobile-footer-link">
        <div className="column small-4">
          <a
            target="_blank"
            rel="noopener"
            href="/vn/ve-fun88/"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'About_footer_homepage']);"
          >
            <div className="white text-align-left">Liên Hệ Fun88</div>
          </a>
        </div>
        <div className="column small-4">
          <a
            rel="noopener"
            href="#"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Affiliate_footer_homepage']); Page.openAffURL('/lmr/vi-vn')"
          >
            <div className="white text-align-left">Đại Lý</div>
          </a>
        </div>
        <div className="column small-4">
          <a
            target="_blank"
            rel="noopener"
            href="/vn/help/policy-rulesandregulations.htm"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'RulesRegulations_footer_homepage']);"
          >
            <div className="white text-align-left">Quy định và Luật lệ</div>
          </a>
        </div>
      </div>
      <div className="row expanded collapse small-12">
        <div className="column small-4">
          <a
            target="_blank"
            rel="noopener"
            href="/vn/help/policy-responsiblegaming.htm"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Disclaimer_footer_homepage']);"
          >
            <div className="white text-align-left">
              Đặt Cược <br />
              có Trách Nhiệm
            </div>
          </a>
        </div>
        <div className="column small-4">
          <a
            target="_blank"
            rel="noopener"
            href="/vn/help/policy-privacy.htm"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Policy_footer_homepage']);"
          >
            <div className="white text-align-left">
              Chính sách <br />
              Bảo mật
            </div>
          </a>
        </div>
        <div className="column small-4">
          <a
            target="_blank"
            rel="noopener"
            href="/vn/help/policy-termsandcondition.htm"
            xxclick="_paq.push(['trackEvent', 'footer_homepage', 'click', 'Terms_footer_homepage']);"
          >
            <div className="white text-align-left">
              Điều kiện và <br />
              Điều khoản
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="row expanded collapse large-8 small-12 show-for-large">
      <div className="MirrorSiteLinkWrapper">
        <div className="MirrorSiteLink">
          <div id="BodyContentPlaceHolderBase_FooterControl_MirrorSite_Position_1">
            <a rel="nofollow" href="https://www.fun88z.com/vn" target="_blank">
              FUN88 Việt Nam
            </a>
          </div>
          <div id="BodyContentPlaceHolderBase_FooterControl_MirrorSite_Position_2">
            <a rel="nofollow" href="/cn" target="_blank">
              FUN88 中文
            </a>
          </div>
          <div id="BodyContentPlaceHolderBase_FooterControl_MirrorSite_Position_3">
            <a rel="nofollow" href="https://www.fun88asia8.com/th" target="_blank">
              FUN88 ภาษาไทย
            </a>
          </div>
          <div id="BodyContentPlaceHolderBase_FooterControl_MirrorSite_Position_4">
            <a rel="nofollow" href="https://www.fun88z.com/vn/en" target="_blank">
              FUN88 ENGLISH
            </a>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div className="row expanded collapse" style={{ padding: "0px 0" }}>
      <div className="column small-12">
        <div
          className="DisclaimerLine lazy"
          data-original="/ft/Disclaimer.svg"
          style={{
            backgroundImage:
              'url("/ft/Disclaimer.svg")'
          }}
        ></div>
      </div>
    </div>
    <div
      className="row expanded collapse hide-for-large"
      style={{ padding: "0px 0" }}
    >
      <div className="column small-12">
        <div
          onClick={()=>{
            this.setState(
              reducer(this.state, {type: LANGMODAL_SHOW_ACTION})
            )
          }}
          className="row language-dropdown"
          data-open="selectRegionLanguage"
          aria-controls="selectRegionLanguage"
          aria-haspopup="true"
          tabIndex={0}
        >
          <div className="mobile-region-selector column small-4">
            <span
              className="lazy flag-icons flag-icons-VN"
              data-original="/ft/flag-icons.webp"
              style={{
                backgroundImage:
                  'url("/ft/flag-icons.webp")'
              }}
            />
          </div>
          <div className="column small-5 language-dropdown-text">
            <div className="white">Tiếng Việt</div>
          </div>
          <div className="column small-1 language-dropdown-icon">
            <span className="icon icon-fun88_arrow_down_triangle white" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="footer-forth-wrapper vi">
    <div className="footer-content-container"></div>
  </section>
</footer>

				{this.state.telegramModal &&
					<Modal
						zIndex={9999}
						visible={true}
					>
						<img width="250" src={'/static/images/hayhar/telegram-qrcode.jpeg'} />
						<div className="closeModal"onClick={()=> this.setState({telegramModal: false})}>&nbsp; &nbsp; ĐÓNG</div>
					</Modal>
				}
				{this.state.lineModal &&
					<Modal
						zIndex={9999}
						visible={true}
					>
						<img width="250" src={'/static/images/hayhar/line-qrcode.jpg'} />
						<div className="closeModal"onClick={()=> this.setState({lineModal: false})}>&nbsp; &nbsp; ĐÓNG</div>
					</Modal>
				}

        {this.render2024LangModal()}

        <div
          id="modal-Telegram"
          className="reveal full modal-Container without-overlay"
          data-reveal="ae0rbq-reveal"
          style={{ zIndex: 4, top: 0, left: 0, margin: 0, display: this.state.telegramModalDisplay ? 'block' : 'none'}}
          role="dialog"
          aria-hidden="false"
          data-yeti-box="modal-Telegram"
          data-resize="modal-Telegram"
          data-events="resize"
          tabIndex={-1}
          onClick={()=>{
            this.setState(
              reducer(this.state, {type: TELEGRAM_CLOSER_SHOW_ACTION})
            )
          }}
        >
          <div className="modal-small-popup">
            <div>
              <img
                onClick={()=>{
                  this.setState(
                    reducer(this.state, {type: TELEGRAM_CLOSER_SHOW_ACTION})
                  )
                }}
                className=""
                alt="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                title="Fun88K - Website Chính Thức | Link đăng nhập vào Fun88 chuẩn"
                src="https://cache.fzkdsp.com//Assets/Images/footer/Telegram/Telegram-vi.jpg"
                style={{}}
              />
              <div className="fun-smallpopup-close" data-close="" style={{height: this.state.telegramModalCloserHeight}} onClick={()=>{
                this.setState(
                  reducer(this.state, {type: TELEGRAM_HIDE_ACTION})
                )
              }}>
                ĐÓNG
              </div>
            </div>
          </div>
        </div>

			</>
		)
	}
}

export default Footer;
